import React from "react";

// Root Include
//const Root = React.lazy(() => import("./pages/Home/indexRoot"));

//Main Index
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const Index = React.lazy(() => import("./pages/Index"));
const AboutUs = React.lazy(() => import("./pages/Pages/AboutUs"));
// const PlatformFee = React.lazy(() => import("./pages/Pages/Forms/PlatformFee"));
// const Testimonial = React.lazy(() => import("./pages/Pages/Testimonial"));
// const CourseDetail = React.lazy(() =>
//   import("./pages/Pages/Course/CourseDetail")
// );
// const LandingPageArabic = React.lazy(() =>
//   import("./pages/Pages/LandingPages/Arabic")
// );
// const ClassDetail = React.lazy(() =>
// import("./pages/Pages/Course/ClassDetail")
// );
// const ArticleList = React.lazy(() =>
//   import("./pages/Pages/Articles/ArticleList")
// );
// const ArticleDetail = React.lazy(() =>
//   import("./pages/Pages/Articles/ArticleDetail")
// );
const Home = React.lazy(() =>
  import("./pages/Pages/Shop/Home/indexComingSoon")
);
const Shop = React.lazy(() =>
  import("./pages/Pages/Shop")
);
const ShopCategories = React.lazy(() =>
  import("./pages/Pages/Shop/indexCategories")
);
const ShopDetail = React.lazy(() =>
  import("./pages/Pages/Shop/ProductDetail")
);
// const Categories = React.lazy(() =>
//   import("./pages/Pages/Categories")
// );
// const SkillCourse = React.lazy(() =>
//   import("./pages/Pages/Categories/skillCourse")
// );
// const LiveClass = React.lazy(() =>
//   import("./pages/Pages/Categories/liveClass")
// );
// const Search = React.lazy(() =>
//   import("./pages/Pages/Categories/search")
// );
const ContactUs = React.lazy(() =>
  import("./pages/Pages/ContactUs")
);
const Profile = React.lazy(() =>
  import("./pages/Pages/Profile")
);
const Orders = React.lazy(() =>
  import("./pages/Pages/Profile/Orders")
);
// const ActiveStudent = React.lazy(() => import("./pages/Pages/Profile/ActiveStudent"));
// const TeacherFee = React.lazy(() => import("./pages/Pages/Profile/TeacherFee"));
// const TeacherClassFee = React.lazy(() => import("./pages/Pages/Profile/TeacherClassFee"));
// const TeacherClassSemiPrivatFee = React.lazy(() => import("./pages/Pages/Profile/TeacherClassSemiPrivatFee"));
// const TeacherMenu = React.lazy(() =>
//   import("./pages/Pages/Profile/TeacherMenu")
// );
// const TeacherSOP = React.lazy(() =>
//   import("./pages/Pages/Profile/TeacherSOP")
// );
// const CourseForm = React.lazy(() =>
//   import("./pages/Pages/Forms/CourseForm")
// );
// const RegisterAsTeacher = React.lazy(() =>
//   import("./pages/Pages/Forms/RegisterAsTeacher")
// );
const TermsConditions = React.lazy(() => import("./pages/Pages/TermConditions"));
const PrivacyPolicy = React.lazy(() => import("./pages/Pages/PrivacyPolicy"));
// const Invoice = React.lazy(() =>
//   import("./pages/Pages/Invoice")
// );
const Login = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverLogin")
);
const ForgotPassword = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageRePasswordThree")
);
const CreateNewPassword = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCreateNewPassword")
);
const Signup = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverSignup")
);
const Logout = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageLogout")
);
// const LoginSuccess = React.lazy(() =>
//   import("./pages/Pages/AuthPages/PageLoginSuccess")
// );

const VerifyEmail = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageVerifyEmail")
);

const orderPayment = React.lazy(() =>
  import("./pages/Pages/Forms/OrderPayment")
);

// const SemiPrivat = React.lazy(() => 
//   import("./pages/Pages/Course/SemiPrivat")
// )


const routes = [
  //routes without Layout

  //{ path: "/about", component: AboutUs, isTopbarDark: true },
  // { path: "/invoice/:id", component: Invoice, isWithoutLayout: true, isTopbarDark: true },
  // { path: "/platform-fee", component: PlatformFee, isTopbarDark: true },
  // { path: "/testimoni", component: Testimonial, isTopbarDark: true },
  // { path: "/konfirmasi-pembayaran/:id", component: orderPayment, isTopbarDark: true, isProtected:true },
  // { path: "/login", component: Login, isWithoutLayout: true, isTopbarDark: true },
  // { path: "/logout", component: Logout, isWithoutLayout: true, isTopbarDark: true },
  // { path: "/forgot-password", component: ForgotPassword, isWithoutLayout: true, isTopbarDark: true },
  // { path: "/signup", component: Signup, isWithoutLayout: true, isTopbarDark: true },
  // { path: "/login-success", component: LoginSuccess, isWithoutLayout: true },
  // { path: "/auth/verify-email/:id", component: VerifyEmail, isWithoutLayout: true },
  // { path: "/auth/verify-forgot-password/:id", component: CreateNewPassword, isWithoutLayout: true },
  // { path: "/verifyemail", component: VerifyEmail, isWithoutLayout: true },
  // { path: "/terms-conditions", component: TermsConditions, isTopbarDark: true },
  // { path: "/privacy", component: PrivacyPolicy, isTopbarDark: true },
  // { path: "/contact", component: ContactUs, isTopbarDark: true },
  // { path: "/profile", component: Profile, isProtected:true },
  // { path: "/profile/orders", component: Orders, isProtected:true },
  // { path: "/profile/active-student", component: ActiveStudent, isProtected:true },
  // { path: "/profile/teacher-fee/:id", component: TeacherFee, isProtected:true },
  // { path: "/profile/teacher-class-fee/:id", component: TeacherClassFee, isProtected:true },
  // { path: "/profile/teacher-class-semi-privat-fee/:id", component: TeacherClassSemiPrivatFee, isProtected:true },
  // { path: "/profile/teacher-menu", component: TeacherMenu, isProtected:true },
  // { path: "/profile/teacher-sop", component: TeacherSOP, isProtected:true },
  // { path: "/pendaftaran-privat/:id", component: CourseForm, isTopbarDark: true },
  // { path: "/teach-registration", component: RegisterAsTeacher,  isTopbarDark: true },

  //Index root
  // { path: "/categories", component: Shop },
  // { path: "/:id", component: ShopCategories },
  // { path: "/product/:id", component: ShopDetail },
  // { path: "/search", component: Search, isTopbarDark: true },
  // { path: "/les/:id", component: CourseDetail },
  // { path: "/kursus/:id", component: CourseDetail },
  // { path: "/kelas/:id", component: ClassDetail },
  // { path: "/kelas-semi-privat/:id", component: SemiPrivat },
  // { path: "/categories/:id", component: Categories },
  // { path: "/up-skill/:id", component: SkillCourse, isTopbarDark: true },
  // { path: "/live-class", component: LiveClass, isTopbarDark: true },
  // // { path: "/article", component: ArticleList },
  // { path: "/blog", component: ArticleList },
  // { path: "/les-bahasa-arab", component: LandingPageArabic, isTopbarDark: true },
  // // { path: "/article/:id", component: ArticleDetail },
  // { path: "/blog/:id", component: ArticleDetail },
  { path: "/", component: Home, isWithoutLayout:true, exact: true },
  { component: PageError, exact: false, isTopbarDark: true },
];

export default routes;
