import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
// import { BrowserTracing } from "@sentry/tracing";

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Sentry.init({
//   dsn: "https://362491fe68de4ef39f059bb395ca4796@o1417780.ingest.sentry.io/6760536",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
