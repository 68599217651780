import gql from 'graphql-tag';

export const GET_CATEGORIES = gql`
query {
    categories{
      id,
      title,
      description,
      slug,
      icon,
      type,
      order
    }
  }
`

export const GET_CATEGORY_DETAIL = gql`
query ($slug: String){
    categories (slug: $slug) {
      id,
      title,
      description,
      icon,
      order,
      slug,
      overview,
      overview2,
      overview3,
      media,
      courses{
        id,
        title,
        image,
        isPublish,
        lectures,
        sort,
        benefit,
        schedule,
        priceTier1,
        shortDescription,
        slug,
        type
      }
    }
  }
`

export const GET_COURSES = gql`
query {
    courses{
      id,
      title,
      image,
      shortDescription,
      slug,
      type,
      islamRelated,
      isPublish,
      isFeatured,
      lectures,
      schedule,
      benefit,
      priceTier1,
      teacher {
        firstName,
        lastName,
        gender
      }
    }
  }
`

export const GET_COURSE_DETAIL = gql`
query ($slug: String) {
    courses (slug: $slug) {
      id,
      title,
      subTitle,
      image,
      bannerImage,
      description,
      shortDescription,
      slug,
      lectures,
      priceTier1,
      priceTier2,
      priceTier3,
      islamRelated,
      isPublish,
      benefit,
      schedule,
      requirement,
      type,
      gender,
      categorySlug,
      teacher {
        id
        firstName
        data
        lastName
        data
        image
        gender
        experience
        latestEducation
      }
    }
  }
`

export const GET_ARTICLES = gql`
query {
    articles {
      id
      title
      content
      image
      imageThumbnail
      tags
      slug
      isPublish
      isFeatured
      updatedAt
    }
  }
`

export const GET_ARTICLE_DETAIL = gql`
query ($slug: String) {
    articles (slug: $slug) {
      id
      title
      content
      updatedAt
      image
      slug
      tags
      comments{
        id
        name
        desc
        createdAt
      }
      author {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_CONTACT = gql`
query ($subId: String) {
    contactById(subId: $subId){
      id
      subId
      firstName
      lastName
      dateBirth
      gender
      email
      image
      isTeacher
      phoneNumber
      address
      experience
      latestEducation
    }
  }
`

export const GET_USER_DATA = gql`
query ($id: ID) {
    UserById(id: $id){
      id
      firstName
      lastName
      email
    }
  }
`

export const GET_USER_DETAIL = gql`
query ($subId: String) {
    userDetailById(subId: $subId){
      id
      name
      image
      gender
      dateBirth
      phoneNumber
      address
      activeStudent
      detailStudent{
        id
        title
        fullName
        phoneNumber
        gender
        age
        address
        session
        duration
        cycle
        present{
          title
          timesheet{
            dateClass
            timeClass
            subjects
            material
            studentTask
            blocker
            isValid 
            adminNotes
            screenshot
          }
        }
      }
    }
  }
`

export const CREATE_CONTACT = gql`
mutation (
  $subId: String
  $firstName: String
  $lastName: String
  $gender: String
  $dateBirth: String
  $email: String
  $image: String
  $isTeacher: Int
  $phoneNumber: String
  $address: String
  ) {
    createContact(
      subId: $subId
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      dateBirth: $dateBirth
      email: $email
      image: $image
      isTeacher: $isTeacher
      phoneNumber: $phoneNumber
      address: $address
    )
      {
        id
    }
  }
`

export const CREATE_CLASS_ORDER = gql`
mutation (
  $fullName: String
  $email: String!
  $phoneNumber: String!
  $courseId: String!
  $userId: String!
  $gender: String
  $status: Int
  $totalPrice: Int
  $class: Int
  $age: Int
  $address: String
  $orderTitle: String
  ) {
    createOrder(
      class: $class
      courseId: $courseId
      email: $email
      fullName: $fullName
      address: $address
      gender: $gender
      age: $age
      orderTitle: $orderTitle
      phoneNumber: $phoneNumber
      status: $status
      totalPrice: $totalPrice
      userId: $userId
    )
      {
        id
    }
  }
`

export const GET_ORDER_ID = gql`
query ($id: ID) {
    orderById(id: $id){
      id
      orderId
      status
      email
      courseId
      orderTitle
      totalPrice
    }
  }
`

export const GET_ORDERS = gql`
query {
    orders{
      id
      orderId
      status
      totalPrice
      orderTitle
      createdAt
      class
    }
  }
`

export const UPDATE_USER_DETAIL = gql`
mutation (
  $subId: String!
  $name: String
  $phoneNumber: String
  $gender: String
  $dateBirth: String
  $address: String
  ) {
    updateUserDetail(
      subId: $subId
      name: $name
      phoneNumber: $phoneNumber
      gender: $gender
      dateBirth: $dateBirth
      address: $address
    )
      {
        id
    }
  }
`

export const ADD_TIMESHEET = gql`
mutation (
  $id: ID!
  $timesheet: StudentInputType!
  $cycle: Int
  ) {
    addTimesheetStudent(
      id: $id
      timesheet: $timesheet
      cycle: $cycle
    )
      {
        id
    }
  }
`

export const UPDATE_ORDER = gql`
mutation (
  $id: ID!
  $status: Int
  $imageProof: String
  ) {
    updateOrder(
      id: $id
      status: $status
      imageProof: $imageProof
    )
      {
        id
    }
  }
`

export const POST_COMMENT = gql`
mutation (
  $userId: String!
  $blogId: String!
  $name: String
  $desc: String
  $createdAt: String
  $updatedAt: String
  ) {
    postComment(
      userId: $userId
      blogId: $blogId
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
      desc: $desc
    )
      {
        id
    }
  }
`

export const CREATE_ORDER = gql`
mutation (
  $fullName: String,
  $email: String!,
  $age: Int,
  $religion: String,
  $job: String,
  $phoneNumber: String!,
  $address: String,
  $birthDate: String,
  $gender: String,
  $courseId: String!,
  $userId: String!,
  $status: Int,
  $totalPrice: Int,
  $discount: Int,
  $class: String
  ) {
    createOrder(
      userId: $userId,
      fullName: $fullName,
      email: $email,
      gender: $gender,
      age: $age,
      religion: $religion,
      job: $job,
      status: $status,
      phoneNumber: $phoneNumber,
      birthDate: $birthDate,
      address: $address,
      courseId: $courseId,
      totalPrice: $totalPrice,
      discount: $discount,
      class: $class
    )
      {
        id
    }
  }
`

export const CREATE_ENROLLMENT = gql`
mutation (
  $fullName: String,
  $email: String!,
  $age: Int,
  $religion: String,
  $job: String,
  $phoneNumber: String!,
  $address: String,
  $birthDate: String,
  $gender: String,
  $courseId: String!,
  $status: Int,
  $totalPrice: Int,
  $discount: Int,
  $class: Int,
  $sessionTime: Int,
  $courseTime: String
  $courseDay: [String],
  $info: String,
  $enrollTitle: String
  ) {
    createEnrollment(
      fullName: $fullName,
      email: $email,
      gender: $gender,
      age: $age,
      religion: $religion,
      job: $job,
      status: $status,
      phoneNumber: $phoneNumber,
      birthDate: $birthDate,
      address: $address,
      courseId: $courseId,
      totalPrice: $totalPrice,
      discount: $discount,
      class: $class,
      sessionTime: $sessionTime,
      courseTime: $courseTime,
      courseDay: $courseDay,
      info: $info,
      enrollTitle: $enrollTitle
    )
      {
        id
    }
  }
`

export const GET_ENROLL_ID = gql`
query ($id: ID){
  enrollById(id:$id){
    id
    enrollId
    enrollTitle
    fullName
    email
    phoneNumber
    gender
    age
    religion
    job
    birthDate
    address
    courseId
    totalPrice
    status
    courseTime
    courseDay
    class
    discount
    info
    sessionTime
    createdAt
    updatedAt
  }
}
`