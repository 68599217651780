import React, { Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

// Import Css
// import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./assets/css/font.css";
//import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import ProtectedRoute from "./auth/protected-route";

// Include Routes
import routes from "./routes";

function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

const App = () => {

  const Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Router>
      <GoogleOAuthProvider clientId="897149939752-2rvhgvup0m8tngi0p09te18bg81dqlkk.apps.googleusercontent.com">
        <Suspense fallback={Loader()}>
          <Switch>
            {routes.map((route, idx) =>
              route.isWithoutLayout ? (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={idx}
                />
              ) : 
              (route.isProtected)? (
                <ProtectedRoute
                  path={route.path}
                  exact
                  component={withLayout(route.component, route.isTopbarDark)}
                  key={idx}
                />
              ) : (
                <Route
                  path={route.path}
                  exact
                  component={withLayout(route.component, route.isTopbarDark)}
                  key={idx}
                />
              )
            )}
          </Switch>
        </Suspense>
        </GoogleOAuthProvider>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(App);
