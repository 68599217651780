import React, { useEffect, useState, Suspense } from "react";
import { withRouter } from "react-router-dom";

import Tagline from "./Tagline";

//Import Icons
import ReactWhatsapp from 'react-whatsapp';
import wa from "../../assets/images/wa2.svg";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const TopbarDetail = React.lazy(() => import('./TopBarDetail'));
const TopbarSticky = React.lazy(() => import("./TopBarSticky"));
const Footer = React.lazy(() => import("./Footer"));

// const CustomDot = () => {
//   return (
//     <React.Fragment>
//       <FeatherIcon icon="arrow-up" className="icons" />
//     </React.Fragment>
//   );
// };

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const Layout = (props) => {
  const pathname = props.location.pathname.indexOf('article');
  const pathname2 = props.location.pathname.indexOf('categories');
  const pathname3 = props.location.pathname.indexOf('kelas');
  const pathname4 = props.location.pathname.indexOf('les/');
  const pathname5 = props.location.pathname.indexOf('kursus');
  const pathname6 = props.location.pathname.indexOf('profile');
  // const pathname7 = props.location.pathname.indexOf('les-bahasa-arab');
  const arr = [pathname3, pathname4, pathname5, pathname6]
  const checker = arr.some(v => v !== -1)


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;



  return (
    <React.Fragment>
        <Suspense fallback={Loader()}>
            {/* <TopbarSticky isMobile={isMobile} isCourseDetail={false}/> */}
          
          {props.children}

          {/* <Footer /> */}
          {!isMobile &&
          <div className="float-wa">
          <ReactWhatsapp className="wa-css text-foot p-0" number="62896-8859-9012" message="">
                <img src={wa} width={20} height={20} alt="float logo whatsapp" />&nbsp; <strong className="text-mute" style={{color:'#FFF',fontSize:14}}>WhatsApp</strong>
                </ReactWhatsapp>
          </div>
          }
          {isMobile &&
          <div className="float-wa" style={{right:20}}>
          <ReactWhatsapp className="wa-css text-foot p-0" number="62896-8859-9012" message="">
                <img src={wa} width={20} height={20} alt="whatsapp"/>
                </ReactWhatsapp>
          </div>
          }

          {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
          {/* scrollup button */}
          {/* <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36 }}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton> */}
          {/* </div> */}

          {/* theme switcher */}
          {/* <ThemeSwitcher /> */}
        </Suspense>
      </React.Fragment>
  )
}

export default withRouter(Layout);
