import { types, flow } from "mobx-state-tree";
import makeApolloClient from '../graphql/apollo';
import { GET_CATEGORIES, GET_COURSES, GET_ARTICLES, GET_COURSE_DETAIL,GET_ARTICLE_DETAIL, CREATE_ENROLLMENT, UPDATE_USER_DETAIL, GET_ENROLL_ID, POST_COMMENT,
  GET_CATEGORY_DETAIL, CREATE_CONTACT, GET_USER_DETAIL, GET_CONTACT, GET_USER_DATA, ADD_TIMESHEET } from '../constants/GraphQl';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { UPDATE_ORDER, CREATE_CLASS_ORDER, GET_ORDER_ID, GET_ORDERS } from '../constants/GraphQl';

const CategoryModel = types.model("CategoryModel", {
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string, ""),
  description: types.maybeNull(types.string, ""),
  overview: types.maybeNull(types.string, ""),
  overview2: types.maybeNull(types.string, ""),
  overview3: types.maybeNull(types.string, ""),
  media: types.array(types.string),
  icon: types.maybeNull(types.string, ""),
  slug: types.maybeNull(types.string, ""),
  type: types.maybeNull(types.number, ""),
  order: types.maybeNull(types.number, ""),
})

const ContactModel = types.model("ContactModel", {
  id: types.maybeNull(types.string),
  subId: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string, ""),
  lastName: types.maybeNull(types.string, ""),
  gender: types.maybeNull(types.string),
  dateBirth: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
  isTeacher: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  data: types.maybeNull(types.string),
  experience: types.maybeNull(types.string),
  latestEducation: types.maybeNull(types.string),
})

const CourseModel = types.model("CourseModel", {
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string, ""),
  subTitle: types.maybeNull(types.string, ""),
  description: types.maybeNull(types.string, ""),
  shortDescription: types.maybeNull(types.string, ""),
  image: types.maybeNull(types.string, ""),
  bannerImage: types.maybeNull(types.string, ""),
  teacher: types.array(ContactModel),
  benefit: types.array(types.string),
  gender: types.array(types.string),
  slug: types.maybeNull(types.string, ""),
  schedule: types.maybeNull(types.string, ""),
  requirement: types.maybeNull(types.string, ""),
  lectures: types.maybeNull(types.number, ""),
  priceTier1: types.maybeNull(types.number, ""),
  priceTier2: types.maybeNull(types.number, ""),
  priceTier3: types.maybeNull(types.number, ""),
  priceGroup1: types.maybeNull(types.number, ""),
  priceGroup2: types.maybeNull(types.number, ""),
  priceGroup3: types.maybeNull(types.number, ""),
  islamRelated: types.maybeNull(types.boolean, ""),
  isFeatured: types.maybeNull(types.boolean),
  isPublish: types.maybeNull(types.boolean),
  categorySlug: types.maybeNull(types.string, ""),
  type: types.maybeNull(types.string, "")
})

const CommentModel = types.model("CommentModel", {
  id: types.maybeNull(types.string),
  userId: types.maybeNull(types.string, ""),
  blogId: types.maybeNull(types.string, ""),
  name: types.maybeNull(types.string, ""),
  desc: types.maybeNull(types.string, ""),
  createdAt: types.maybeNull(types.string, ""),
  updatedAt: types.maybeNull(types.string, ""),
})

const ArticleModel = types.model("ArticleModel", {
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string, ""),
  content: types.maybeNull(types.string, ""),
  updatedAt: types.maybeNull(types.string, ""),
  image: types.maybeNull(types.string, ""),
  imageThumbnail: types.maybeNull(types.string, ""),
  tags: types.array(types.string),
  author: types.optional(ContactModel, {}),
  isFeatured: types.maybeNull(types.boolean),
  isPublish: types.maybeNull(types.boolean),
  slug: types.maybeNull(types.string, ""),
  comments: types.array(CommentModel),
})

const TimesheetModel = types.model("TimesheetModel", {
  dateClass: types.maybeNull(types.string),
  timeClass: types.maybeNull(types.string),
  subjects: types.maybeNull(types.string),
  material: types.maybeNull(types.string),
  studentTask: types.maybeNull(types.string), 
  blocker: types.maybeNull(types.string),
  isValid: types.maybeNull(types.boolean),
  adminNotes: types.maybeNull(types.string),
  screenshot: types.maybeNull(types.string, ""),
})

const PresentModel = types.model("PresentModel", {
  title: types.maybeNull(types.string),
  timesheet: types.array(TimesheetModel)
})

const StudentModel = types.model("StudentModel", {
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string, ""),
  fullName: types.maybeNull(types.string, ""),
  phoneNumber: types.maybeNull(types.string, ""),
  gender: types.maybeNull(types.string, ""),
  age: types.maybeNull(types.number, ""),
  address: types.maybeNull(types.string, ""),
  session: types.maybeNull(types.number, ""),
  duration: types.maybeNull(types.number, ""),
  cycle: types.maybeNull(types.number, ""),
  present: types.array(PresentModel),
})

const ProfileModel = types.model("ProfileModel", {
  id: types.maybeNull(types.string),
  subId: types.maybeNull(types.string, ""),
  name: types.maybeNull(types.string, ""),
  gender: types.maybeNull(types.string, ""),
  image: types.maybeNull(types.string, ""),
  address: types.maybeNull(types.string, ""),
  phoneNumber: types.maybeNull(types.string, ""),
  dateBirth: types.maybeNull(types.string, ""),
  activeStudent: types.array(types.string),
  detailStudent: types.array(StudentModel),
})

const OrderModel = types.model("OrderModel", {
  id: types.maybeNull(types.string),
  orderId: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string, ""),
  email: types.maybeNull(types.string, ""),
  gender: types.maybeNull(types.string, ""),
  age: types.maybeNull(types.number, ""),
  religion: types.maybeNull(types.string, ""),
  job: types.maybeNull(types.string, ""),
  phoneNumber: types.maybeNull(types.string, ""),
  birthDate: types.maybeNull(types.string, ""),
  address: types.maybeNull(types.string, ""),
  courseId: types.maybeNull(types.string, ""),
  totalPrice: types.maybeNull(types.number, ""),
  discount: types.maybeNull(types.number, ""),
  class: types.maybeNull(types.number, ""),
  orderTitle: types.maybeNull(types.string, ""),
  status: types.maybeNull(types.number, ""),
  createdAt: types.maybeNull(types.string)
})

const EnrollModel = types.model("EnrollModel", {
  id: types.maybeNull(types.string),
  enrollId: types.maybeNull(types.string),
  enrollTitle: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string, ""),
  email: types.maybeNull(types.string, ""),
  gender: types.maybeNull(types.string, ""),
  age: types.maybeNull(types.number, ""),
  religion: types.maybeNull(types.string, ""),
  job: types.maybeNull(types.string, ""),
  phoneNumber: types.maybeNull(types.string, ""),
  birthDate: types.maybeNull(types.string, ""),
  address: types.maybeNull(types.string, ""),
  courseId: types.maybeNull(types.string, ""),
  totalPrice: types.maybeNull(types.number, ""),
  discount: types.maybeNull(types.number, ""),
  class: types.maybeNull(types.number, ""),
  status: types.maybeNull(types.number, ""),
  sessionTime: types.maybeNull(types.number, ""),
  courseTime: types.maybeNull(types.string),
  courseDay: types.array(types.string),
  info: types.maybeNull(types.string, ""),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string)
})

export const EduStore = types.model("EduStore", {
    token: types.maybeNull(types.string, ""),
    orderDetail: types.optional(OrderModel, {}),
    enrollDetail: types.optional(EnrollModel, {}),
    isAuthenticated: false,
    isVerified: false,
    userId: types.maybeNull(types.string, ""),
    userProfile: types.optional(ProfileModel,{}),
    userName: types.maybeNull(types.string, ""),
    userEmail: types.maybeNull(types.string, ""),
    userType: types.maybeNull(types.string, ""),
    categories: types.array(CategoryModel),
    recentPost: types.array(ArticleModel),
    classCategories: types.array(CategoryModel),
    skillCategories: types.array(CategoryModel),
    categoryDetail: types.optional(CategoryModel, {}),
    courses: types.array(CourseModel),
    skillCourses: types.array(CourseModel),
    classCourses: types.array(CourseModel),
    allClassCourses: types.array(CourseModel),
    allSemiCourses: types.array(CourseModel),
    featuredCourses: types.array(CourseModel),
    orders: types.array(OrderModel),
    courseDetail: types.optional(CourseModel, {}),
    articles: types.array(ArticleModel),
    articlesFeatured: types.array(ArticleModel),
    articleDetail: types.optional(ArticleModel, {}),
    perPage: 24,
    lastPage: 0,
    currentPage: 1,
    state: 'pending',
    errorMessage: ''
  })
  .actions(self => {
    const getQlClient = (token) => {
      //console.log('fetch', token)
      return makeApolloClient(token)
    }
    const setToken = (token) => {
      self.token = token
    }
    const getLocalStorage = async () => {
      const data = await localStorage.getItem("annajm-token");
      return data
    }
    const removeLocalStorage = async () => {
      const data = await localStorage.removeItem('annajm-token');
      return data
    }
    const getToken = flow(function* getToken() {
      try {
        const data = yield getLocalStorage()
        if (data == null || data == '') {
          self.isAuthenticated = false
        } else {
          const decoded = jwt_decode(data);
          self.token = data
          if (decoded.id !== " ") {
            self.userId = decoded.id
            self.userName = decoded.name
            self.userType = decoded.userType
            self.isAuthenticated = true
            self.isVerified = decoded.verifiedStatus
            return decoded
          }
          self.isAuthenticated = false
          return false
        }
      } catch (err) {
        self.isAuthenticated = false
      }
    })
    const createEnrollment = flow(function* createEnrollment(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: CREATE_ENROLLMENT,
            variables
        })
        self.state = 'done'
        return response.data.createEnrollment
      } catch (error) {
        console.log('er', error)
        self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        self.state = 'error'
        return error
      }
    })
    const createOrder = flow(function* createOrder(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: CREATE_CLASS_ORDER,
            variables
        })
        self.state = 'done'
        return response.data.createOrder
      } catch (error) {
        if (JSON.stringify(error, null, 2).indexOf('Email already exist') !== -1) {
          self.errorMessage = 'Email ini sudah terdaftar'
        } else {
          self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        }
        
        self.state = 'error'
        return error
      }
    })
    const getMyOrder = flow(function* getMyOrder() {
      try {
        self.state = 'pending'
        if (self.userId == null) {
          yield self.getToken()
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_ORDERS,
        })
        self.orders = response.data.orders
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getOrderById = flow(function* getOrderById(id) {
      try {
        self.state = 'pending'
        if (self.userId == null) {
          yield self.getToken()
        }
        const variables = {
          id: id
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_ORDER_ID,
            variables
        })
        self.orderDetail = response.data.orderById
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const postComment = flow(function* postComment(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: POST_COMMENT,
            variables
        })
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        console.log(JSON.stringify(error, null, 2));
        self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        self.state = 'error'
        return error
      }
    })
    const addTimesheet = flow(function* addTimesheet(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: ADD_TIMESHEET,
            variables
        })
        self.state = 'done'
        return response.data.createOrder
      } catch (error) {
        console.log('er', error)
        console.log(JSON.stringify(error, null, 2));
        self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        self.state = 'error'
        return error
      }
    })
    const updateUserDetail = flow(function* updateUserDetail(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: UPDATE_USER_DETAIL,
            variables
        })
        self.state = 'done'
        return response.data.createOrder
      } catch (error) {
        console.log('er', error)
        console.log(JSON.stringify(error, null, 2));
        self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        self.state = 'error'
        return error
      }
    })
    const getEnrollId = flow(function* getEnrollId(id) {
      try {
        self.state = 'pending'
        if (self.userId == null) {
          yield self.getToken()
        }
        const variables = {
          id: id
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_ENROLL_ID,
            variables
        })
        self.enrollDetail = response.data.enrollById
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const updateOrder = flow(function* updateOrder(input) {
      try {
        self.state = 'pending'
        const variables = input
        const response = yield self.getQlClient(self.token).mutate({
            mutation: UPDATE_ORDER,
            variables
        })
        self.state = 'done'
        return response.data.createOrder
      } catch (error) {
        console.log('er', error)
        console.log(JSON.stringify(error, null, 2));
        self.errorMessage = 'Ada masalah di system kami, silakan coba lagi'
        self.state = 'error'
        return error
      }
    })
    const uploadPaymentProof = flow(function* uploadPaymentProof(data) {
      try {
        self.state = 'pending'
        const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-payment-proof`, data)
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const uploadScreenshots = flow(function* uploadScreenshots(data) {
      try {
        self.state = 'pending'
        const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-screenshots`, data)
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const forgotPassword = flow(function* forgotPassword(email) {
      try {
        self.state = 'pending'
        const response = yield axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/forgot-password?email=${email}`)
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const sendVerifyEmail = flow(function* sendVerifyEmail(token) {
      try {
        self.state = 'pending'
        const AuthStr = 'Bearer '.concat(token); 
        const response = yield axios.get(`${process.env.REACT_APP_SERVER_URL}/auth/verify-email`,  { headers: { Authorization: AuthStr }})
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const createNewPassword = flow(function* createNewPassword(token, data) {
      try {
        self.state = 'pending'
        const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/forgot-password/${token}`, data)
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const userRegister = flow(function* userRegister(input) {
      try {
        self.state = 'pending'
        let firstname = ''
        let lastname = ''
        if (input.fullname.trim().indexOf(' ') != -1) {
          const name = input.fullname.split(' ')
          firstname = name[0]
          lastname = name[1]
        } else {
          firstname = input.fullname
        }
        const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/register`, {
          user:{
            firstName: firstname,
            lastName: lastname,
            email: input.email,
            password: input.password
          }
        })
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const googleTestLogin = flow(function* googleTestLogin(input) {
      self.state = 'pending'
      //console.log('ac', input.access_token)
      const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/google`, {
        googleToken: input.access_token,
      })
      //console.log('resp', response)
      const decoded = jwt_decode(response.data.authorization);
      self.userId = decoded.id
      self.userName = decoded.name
      self.userType = decoded.userType
      self.isVerified = decoded.verifiedStatus
      localStorage.setItem("annajm-token", response.data.authorization);
      self.isAuthenticated = true
      //self.token = response.data
      self.state = 'done'
    })
    const googleLogin = flow(function* userRegister(data) {
      try {
        self.state = 'pending'
        const decoded = jwt_decode(data);
        self.userId = decoded.id
        self.userName = decoded.name
        self.userType = decoded.userType
        self.isVerified = decoded.verifiedStatus
        localStorage.setItem("annajm-token", data);
        self.isAuthenticated = true
        self.state = 'done'
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const userLogin = flow(function* userRegister(input) {
      try {
        self.state = 'pending'
        const response = yield axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, {
          email: input.email,
          password: input.password
        })
        self.token = response.data
        const decoded = jwt_decode(response.data);
        self.userId = decoded.id
        self.userName = decoded.name
        self.userType = decoded.userType
        self.isVerified = decoded.verifiedStatus
        localStorage.setItem("annajm-token", response.data);
        self.isAuthenticated = true
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log("Failed", error)
        self.errorMessage = error.response.data
        self.state = 'error'
      }
    })
    const userLogout = flow(function* userLogout() {
      try {
        const data = yield removeLocalStorage()
        self.userId = ""
        self.userName = ""
        self.userType = ""
        self.isAuthenticated = false
        self.isVerified = false
      } catch (err) {
        self.isAuthenticated = false
      }
    })
    const getUserData = flow(function* getUserData() {
      try {
        self.state = 'pending'
        if (self.userId == null) {
          yield self.getToken()
        }
        const variables = {
          id: self.userId
        }
        const response = yield self.getQlClient(self.token).query({
          query: GET_USER_DATA,
          variables
        })
        self.userEmail = response.data.UserById.email
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getUserDetail = flow(function* getUserDetail(subId) {
      try {
        self.state = 'pending'
        const variables = {
          subId
        }
        const response = yield self.getQlClient(self.token).query({
          query: GET_USER_DETAIL,
          variables
        })
        console.log('resp',response)
        self.userProfile = response.data.userDetailById
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getCategories = flow(function* getCategories() {
      try {
        self.state = 'pending'
        if (self.categories.length == 0) {
          const response = yield self.getQlClient(self.token).query({
            query: GET_CATEGORIES,
          })
          const fd = response.data.categories.filter((item) => {
            return item.type == 0
          })
          const fd1 = response.data.categories.filter((item) => {
            return item.type == 1
          })
          const fdd = response.data.categories.filter((item) => {
            return item.type == 2
          })
          const sd = fd.sort(function(a, b) {
            return parseFloat(a.order) - parseFloat(b.order);
          });
          self.categories = sd
          self.skillCategories = fd1
          self.classCategories = fdd
          return sd
        }
        self.state = 'done'
        return self.categories
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getCategoryDetail = flow(function* getCategoryDetail(slug) {
      try {
        self.courses = []
        self.skillCourses = []
        self.state = 'pending'
        const variables = {
          slug: slug
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_CATEGORY_DETAIL,
            variables
        })
        self.categoryDetail = response.data.categories[0]
        const fd = response.data.categories[0].courses.filter((item) => {
          return item.isPublish == true
        })
        fd.sort((a, b) => (a.sort) - (b.sort));

        const sd = response.data.categories[0].courses.filter((item) => {
          return item.isPublish == true && item.type == "1"
        })
        const fdd = fd.filter((item) => {
          return item.isFeatured == true
        })
        self.courses = fd
        self.skillCourses = sd
        self.featuredCourses = fdd
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const clearCategory = () => {
      self.categoryDetail = {}
    }
    const getCourses = flow(function* getCourses() {
      try {
        self.courses = []
        self.skillCourses = []
        self.allClassCourses = []
        self.classCourses = []
        self.featuredCourses = []
        self.state = 'pending'
        const response = yield self.getQlClient(self.token).query({
            query: GET_COURSES,
        })
        const fd = response.data.courses.filter((item) => {
          return item.isPublish == true
        })
        const sd = fd.filter((item) => {
          return item.type == "1"
        })
        const fdd = fd.filter((item) => {
          return item.type == "0"
        })
        const fdd2 = fd.filter((item) => {
          return item.type == "2" || item.type == "3"
        })
        const fdd5 = fd.filter((item) => {
          return item.type == "3"
        })
        const fdd3 = fd.filter((item) => {
          return item.isFeatured == true
        })
        const fdd4 = response.data.courses.filter((item) => {
          return item.type == "2"
        })
        self.courses = fdd
        self.skillCourses = sd
        self.classCourses = fdd2
        self.featuredCourses = fdd3
        self.allClassCourses = fdd4
        self.allSemiCourses = fdd5 
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getCourseDetail = flow(function* getCourseDetail(slug) {
      try {
        self.courseDetail = {}
        self.state = 'pending'
        const variables = {
          slug: slug
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_COURSE_DETAIL,
            variables
        })
        self.courseDetail = response.data.courses[0]
        self.state = 'done'
        return response.data.courses[0]
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getArticles = flow(function* getArticles() {
      try {
        self.state = 'pending'
        const response = yield self.getQlClient(self.token).query({
            query: GET_ARTICLES,
        })
        const fd = response.data.articles.filter((item) => {
          return item.isPublish == true
        })
        const fdd = response.data.articles.filter((item) => {
          return item.isPublish == true && item.isFeatured
        })
        // console.log('data', response)
        self.articles = fd
        self.articlesFeatured = fdd
        self.state = 'done'
        self.recentPost = fd.reverse()
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getArticleDetail = flow(function* getArticleDetail(slug) {
      try {
        self.state = 'pending'
        const variables = {
          slug: slug
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_ARTICLE_DETAIL,
            variables
        })
        self.articleDetail = response.data.articles[0]
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const getContact = flow(function* getContact(subId) {
      try {
        self.state = 'pending'
        const variables = {
          subId
        }
        const response = yield self.getQlClient(self.token).query({
            query: GET_CONTACT,
            variables
        })
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    const createContact = flow(function* createContact(input) {
      try {
        self.state = 'pending'
        const variables = {
          input
        }
        const response = yield self.getQlClient(self.token).query({
            query: CREATE_CONTACT,
            variables
        })
        self.state = 'done'
        return response.data
      } catch (error) {
        console.log('er', error)
        self.state = 'error'
      }
    })
    return {
      setToken,
      postComment,
      userLogout,
      createEnrollment,
      createOrder,
      getMyOrder,
      sendVerifyEmail,
      updateOrder,
      getOrderById,
      createNewPassword,
      forgotPassword,
      getToken,
      userRegister,
      getEnrollId,
      uploadPaymentProof,
      updateUserDetail,
      userLogin,
      googleTestLogin,
      googleLogin,
      getContact,
      getUserData,
      createContact,
      getUserDetail,
      clearCategory,
      getQlClient,
      addTimesheet,
      getCategories,
      getCourseDetail,
      getArticleDetail,
      getCategoryDetail,
      uploadScreenshots,
      getArticles,
      getCourses
    }
  });

let _eduStore
export const useEdu = () => {
  if (!_eduStore) {
    _eduStore = EduStore.create({
      categories: [],
      state: 'pending'
    })
  }

  return _eduStore;
}
