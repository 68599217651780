import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Layout/Loading';
import { useEdu } from '../stores/edu'

const ProtectedRoute = (props) => {
  const [loading, setLoading] = useState(true)
  const eduStore = useEdu();

  useEffect(() => {
    loadToken()
  },[])

  const loadToken = async e => {
    setLoading(true)
    await eduStore.getToken()
    setLoading(false)
  }

  if (loading) {
    return <Loading/>
  }

  return eduStore.isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login"
      }}
    />
  )
};

export default ProtectedRoute;